import React from "react";

const Map = () => {
  return (
    <div>
      <section className="map-area">
        <div id="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d241272.23040425428!2d72.869041!3d19.112981!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x35226d4262ca91e!2sJCI%20INDIA%20NATIONAL%20HEAD%20QUARTER!5e0!3m2!1sen!2sin!4v1629701666984!5m2!1sen!2sin"
            className="map"
            title="googlemap"
            allowFullScreen=""
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default Map;
